import { render, staticRenderFns } from "./LoadingScreen.vue?vue&type=template&id=62b0c077&scoped=true&"
import script from "./LoadingScreen.vue?vue&type=script&lang=js&"
export * from "./LoadingScreen.vue?vue&type=script&lang=js&"
import style0 from "./LoadingScreen.vue?vue&type=style&index=0&id=62b0c077&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62b0c077",
  null
  
)

export default component.exports