<template>
  <v-container fluid>
    <div class="mt-5 mb-10 ">
      <div class="d-flex flex-row-reverse">
<!--        <v-btn text class="flag-icon-background flag-icon-vn" @click="changeLocaleVN()">-->
<!--        </v-btn>-->

<!--        <v-btn text class="flag-icon-background flag-icon-gb" @click="changeLocaleEN()">-->
<!--        </v-btn>-->
      </div>

    </div>

    <v-row align="center" justify="center">
      <v-col cols="12" sm="6" xl="4">
        <v-card class="elevation-12">
          <div class="header">
            <p class="text-center"> {{ $t('login.greeting') }} </p>
          </div>

          <v-card-text class="px-10 mt-2">
            <v-form ref="form" v-model="valid" lazy-validation @keyup.enter.native="login">
              <v-text-field v-model="username" :rules="usernameRules" v-bind:label="$t('login.username')" outlined name="username"
                            prepend-inner-icon="person" type="text" required/>

              <v-text-field v-model="password" :append-icon="show ? 'visibility' : 'visibility_off'" :rules="passRules"
                            v-bind:label="$t('login.password')" outlined id="password" name="password"
                            prepend-inner-icon="lock" :type="show ? 'text' : 'password'" required @click:append="show = !show"
              />
            </v-form>

            <div class="d-flex justify-space-between">
              <v-spacer/>
              <span style="font-weight: bold;" @click="showForgotPassword = true"> Quên mật khẩu? </span>
            </div>
            <vue-recaptcha
                sitekey="6Lf5i4IlAAAAABqj4OMOf7qTD0mUSSrU3h6djmOa"
                ref="recaptcha"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
            >
            </vue-recaptcha>
          </v-card-text>

          <v-container class="px-10 pt-2 mb-2" fluid>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="12">
                <v-btn large block color="primary" @click="login" :disabled="!isCapChaValid">{{ $t('login.login') }}</v-btn>
              </v-col>
            </v-row>

            <v-row align="center" justify="center" class="mb-1">
              <span>Chưa có tài khoản? <span @click="register()" style="font-weight: bold;">Đăng ký ngay</span></span>
            </v-row>

            <v-row align="center" justify="center" class="mb-2">
              <span style="font-size: 18px;"> Liên hệ qua zalo số <a href="https://zalo.me/0947324568" target="_blank"> 0947324568 </a> để được hỗ trợ!</span>
<!--              <a href="https://zalo.me/0947324568" target="_blank"> Zalo hỗ trợ 0947324568 </a>-->
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>


    <v-overlay :value="overlay" :z-index="0">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="showForgotPassword" max-width="600px">
      <v-card class="elevation-16 mx-auto">
        <v-card-text>
          <v-card-text class="px-1">
            <span style="font-size: 18px;"> Liên hệ qua zalo số <a href="https://zalo.me/0947324568" target="_blank"> 0947324568 </a> để được hỗ trợ!</span>
          </v-card-text>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions class="justify-space-between">
          <v-spacer></v-spacer>
          <v-btn dark color="primary" @click="showForgotPassword = false">
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :color="color"
        :timeout="2000"
        top
        right
        elevation="24"
        class="mt-5"
    >
      {{ message }}
    </v-snackbar>
  </v-container>

</template>

<script>
import i18n from '@/plugins/i18n';
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  data: () => ({
    show: false,
    valid: true,
    overlay: false,
    isCapChaValid: false,
    snackbar: false,
    message: '',
    color: '#0C8F4D',

    recaptchaToken: '',
    showForgotPassword: false,
    password: '',
    passRules: [
      v => !!v || i18n.t('validate.password_require'),
      v => (v && v.length > 2) || i18n.t('validate.password_invalid'),
    ],
    usernameRules: [
      v => !!v || i18n.t('validate.username_require'),
      v => (v && v.length > 2) || i18n.t('validate.username_invalid'),
    ],
    username: '',
  }),

  components: {
    VueRecaptcha
  },

  async created() {

  },

  methods: {
    changeLocaleVN: function () {
      this.$i18n.locale = 'vn';
      this.$store.dispatch('language/setLanguage', 'vn');
    },
    changeLocaleEN: function () {
      this.$i18n.locale = 'en';
      this.$store.dispatch('language/setLanguage', 'en');
    },
    forgot: function () {
      this.$router.push('forgot');
    },

    onCaptchaVerified: function (recaptchaToken) {
      this.isCapChaValid  = true;
      this.recaptchaToken = recaptchaToken;
    },

    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },

    login: async function () {
      this.$refs.form.validate();

      if (!this.username || this.username.length == 0) {
        return;
      }

      if (!this.password || this.password.length == 0) {
        return;
      }

      this.overlay = true;

      const self = this;
      const requestBody = {
        username: self.username.toLowerCase(),
        password: self.password,
        token: self.recaptchaToken
      };

      try {
        const response =  await this.$login(requestBody);
        // this.$refs.recaptcha.reset();
        this.overlay = false;
        if (response.status == process.env.VUE_APP_SUCCESS_STATUS) {
            // console.log(response.data);
          localStorage.setItem('access_token', response.data.token);
          localStorage.setItem("username", response.data.username);
          localStorage.setItem("userId", response.data.id);
          localStorage.setItem("role_id", response.data.role_id);
          localStorage.setItem("code", response.data.code);

          return await this.$router.push('/');
        } else if (response.status == 304) {
          this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
          this.message = i18n.t('Tài khoản đã bị khóa vĩnh viễn do vi phạm khi sử dụng!')
          this.snackbar = true;
        } else if (response.status == 300) {
          this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
          this.message = i18n.t('login.error_login')
          this.snackbar = true;
        } else {
          this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
          this.message = i18n.t('validate.request_error')
          this.snackbar = true;
        }
      } catch(e) {
        console.log(e.stack);
        alert(i18n.t('validate.request_error'));
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true
      }
    },

    register() {
      this.$router.push('/register');
    },

    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
  }
}
</script>

<style scoped>
.header {
  height: 60px;
}

p {
  color: #1D243E;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  padding-top: 20px;
}

.small-button {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  color: #929AAE;
}

.normal-button {
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
}
</style>
