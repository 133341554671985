<template>
    <v-dialog v-model="loading" fullscreen transition="dialog-bottom-transition" hide-overlay persistent>
        <v-container class="fill-height primary" color="primary" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8">
                    <v-card color="primary" dark flat>
                      <v-img  :src="require('@/assets/logo_icon2.png')" class="my-3" contain height="80"></v-img>
<!--                      <p class="titleWarning"> Trung thực là nguồn gốc của hợp tác lâu dài! </p> <br/>-->
                      <v-card-text>
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                      </v-card-text>

<!--                      <p class="titleWarning"> Website đang bảo trì!!! </p> <br/>-->
<!--                      <p class="subTitle">Dự kiến trong hôm nay bảo trì xong. Các khách hàng đã nạp tiền mà chưa được cộng sẽ được cộng ngay sau khi bảo trì.</p>-->
<!--                      <p class="subTitle">Mong quý khách yên tâm.</p>-->
<!--                      <p class="subTitle"> Tel: 0947324568 </p>-->
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-dialog>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean
        }
    },

    created() {},
}
</script>

<style scoped>
.titleWarning {
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
}

.subTitle {
  text-align: center;
  color: #FFFFFF;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
}
</style>