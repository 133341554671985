<template>
    <v-container class="container-class" fluid @scroll="onScrollList"
                 style="overflow: auto; height: calc(100vh - 56px)">
        <v-row no-gutters>
            <v-col cols="12" sm="3">
                <v-card outlined color="transparent" style="width:100%;">
                    <v-container fluid>
                        <div>
                            <v-row justify="space-between">
                                <v-col cols="12" sm="12" justify="center">
                                    <p class="header-title"> {{ $t('buy_code_management.service_list') }} </p>
                                </v-col>
                            </v-row>
                        </div>
                        <v-row no-gutters>
                            <!--                            services on mobile-->
                            <v-autocomplete
                                    v-if="isMobile"
                                    background-color="#ffffff"
                                    v-model="selectedService"
                                    :items="services"
                                    item-text="name"
                                    v-bind:label="$t('buy_code_management.select')"
                                    return-object
                                    outlined
                                    dense
                                    :height="isMobile ? '48px' : '40px'"
                            />
                            <v-col cols="12" sm="12">
                                <v-autocomplete background-color="#ffffff" v-model="selectNetwork" :items="networks"
                                                item-text="name"
                                                v-bind:label="$t('buy_code_management.network_service')" return-object
                                                outlined dense
                                                :height="isMobile ? '48px' : '40px'"
                                />
                            </v-col>

                            <v-col cols="12" sm="12">
                                <v-text-field background-color="#ffffff" v-model="headerNumber"
                                              v-bind:label="$t('buy_code_management.header_number')" outlined
                                              dense
                                              :height="isMobile ? '48px' : '40px'"
                                />
                            </v-col>

                            <!--                            button hire sim on mobile-->
                            <v-col cols="12" v-if="isMobile">
                                <v-btn
                                        width="100%" height="48px" style="font-size:17px" color="primary"
                                        @click="hireSIM(selectedService)"
                                >
                                    {{ $t('buy_code_management.hire') }}
                                </v-btn>
                            </v-col>

                            <!--                            services on desktop -->
                            <template v-if="!isMobile">
                                <v-col cols="12" sm="12" class="pb-5">
                                    <v-text-field background-color="#ffffff" v-model="serviceName"
                                                  append-icon="search"
                                                  v-bind:label="$t('buy_code_management.search')" hide-details
                                                  outlined
                                                  :dense="true"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" v-for="(service, index) in services" :key="index">
                                    <v-card height="60px" class="ma-1">
                                        <v-btn height="100%" width="100%" outlined depressed color="black"
                                               @click="hireSIM(service)">
                                            <v-avatar size="32" class="mr-1">
                                                <img v-if="service.image" :src="service.image"/>
                                                <img v-else src='@/assets/no-photo.png'/>
                                            </v-avatar>
                                            <v-spacer></v-spacer>
                                            <span class="defaultFont"> {{ service.name }} </span>
                                        </v-btn>
                                    </v-card>
                                </v-col>
                            </template>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>

            <!--            list item on mobile-->
            <template v-if="isMobile">
                <div class="mt-3 mb-2" style="font-size:17px">Tổng giao dịch: {{ totalItem }}</div>
                <InfoItem
                        v-for="item in desserts"
                        :key="item.id" :data="item"
                        :showDetailDialog="showDetailDialog"
                        :copyClipboardInDialog="copyClipboardInDialog"
                        :cancelSMS="cancelSMS"
                        :renewSMS="renewSMS"
                />
            </template>
            <!--            list item on desktop-->
            <v-col v-if="!isMobile" cols="12" sm="9">
                <v-card outlined color="transparent" style="width:100%;">
                    <v-container>
                        <div>
                            <v-row justify="space-between">
                                <v-col cols="12" sm="6" justify="center">
                                    <p class="header-title"> {{ $t('buy_code_management.list_otp') }} </p>
                                </v-col>

                                <v-col cols="12" sm="6" justify="center">
                                    <!--                  <div class="d-flex flex-row-reverse">-->
                                    <!--                    <v-btn color="primary" dark @click="getAllOTP">{{-->
                                    <!--                        $t("common.refresh")-->
                                    <!--                      }}</v-btn>-->
                                    <!--                  </div>-->
                                  <div class="d-flex flex-row-reverse">
<!--                                    <a href="https://www.facebook.com/trunggian.nguyenvandieu" target="_blank">-->
<!--                                      <video class="video" ref="video" loop autoplay muted>-->
<!--                                        <source src="@/assets/Banner.mp4" type="video/mp4">-->
<!--                                      </video>-->
<!--                                    </a>-->

                                    <a href="https://tradeproxy.vn" target="_blank">
                                      <v-img :src="require('@/assets/banner_proxy.jpeg')"></v-img>
                                    </a>
                                  </div>
                                </v-col>
                            </v-row>
                        </div>

                        <v-data-table
                                :headers="headers"
                                :items="desserts"
                                :search="search"
                                :loading="loading"
                                sort-by="email"
                                class="elevation-1"
                                hide-default-footer
                                :items-per-page="itemsPerPage">
                            <template v-slot:top>
                                <v-toolbar flat color="white" class="pt-5">
                                    <v-row justify="start">
                                        <v-col cols="12" sm="2">
                                            <v-autocomplete v-model="itemsPerPage" :items="numberPage" outlined
                                                            :dense="true"></v-autocomplete>
                                        </v-col>

                                        <v-col cols="12" sm="1">
                                        </v-col>

                                        <v-col cols="12" sm="2">
                                        </v-col>

                                        <v-col cols="12" sm="7">
                                            <v-text-field v-model="search" append-icon="search"
                                                          v-bind:label="$t('common.search')" single-line hide-details
                                                          outlined :dense="true"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-toolbar>
                            </template>

                            <template v-slot:[`item.payment`]="{ item }">
                                <span class="text-no-wrap">{{ $thousandSeprator(item.payment) }}</span>
                            </template>

                            <template v-slot:[`item.service`]="{ item }">
                                <div class="contentAddress">
                                    <v-avatar size="30" class="mr-1">
                                        <img v-if="item.service.image" :src="item.service.image"/>
                                        <img v-else src='@/assets/no-photo.png'/>
                                    </v-avatar>

                                    <span class="text-no-wrap"> {{ item.service.name }} </span>
                                </div>
                            </template>

                            <!--              <template v-slot:[`item.otp`]="{ item }">-->
                            <!--                <div class="contentAddress">-->
                            <!--                  <span class="text-no-wrap" style="display: inline-block; width: 60px;"> {{ item.otp }} </span>-->
                            <!--                  <v-icon small class="mr-2" @click="copyClipboard(item.otp)" v-if="item.otp > 0">-->
                            <!--                    content_copy-->
                            <!--                  </v-icon>-->
                            <!--                </div>-->
                            <!--              </template>-->

                            <template v-slot:[`item.type`]="{ item }">
                                <div class="contentAddress" v-if="item.type !== 'audio'">
                                    <span class="text-no-wrap" style="display: inline-block; width: 60px;"> {{
                                        item.otp
                                        }} </span>
                                    <v-icon small class="mr-2" @click="copyClipboard(item.otp)" v-if="item.otp > 0">
                                        content_copy
                                    </v-icon>
                                </div>

                                <vuetify-audio v-else :file="item.audioMp3" color="primary"></vuetify-audio>
                            </template>

                            <template v-slot:[`item.sim`]="{ item }">
                                <div class="contentAddress">
                                    <span class="text-no-wrap" style="display: inline-block;"> {{
                                        item.sim.phone
                                        }} </span>

                                    <v-icon small class="mr-2 ml-1" @click="copyClipboard(item.sim.phone)"
                                            v-if="item.sim.phone > 0">
                                        content_copy
                                    </v-icon>

                                    <v-tooltip
                                            bottom
                                            max-width="300px"
                                            color="primary"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" size="12px" color="white" class="iconBorderGreen"
                                                    @click="renewSMS(item)" v-show="item.status === 1">
                                                autorenew
                                            </v-icon>
                                        </template>
                                        <span>Mua lại</span>
                                    </v-tooltip>
                                    <!--                  <v-tooltip-->
                                    <!--                      bottom-->
                                    <!--                      max-width="300px"-->
                                    <!--                      color="primary"-->
                                    <!--                  >-->
                                    <!--                    <template v-slot:activator="{ on }">-->
                                    <!--                      <v-icon v-on="on" small class="mr-2" @click="$copyText(item.otp)" v-if="item.sim.phone > 0">-->
                                    <!--                        content_copy-->
                                    <!--                      </v-icon>-->
                                    <!--                    </template>-->
                                    <!--                    <span>Click to Copy</span>-->
                                    <!--                  </v-tooltip>-->
                                </div>

                            </template>

                            <template v-slot:[`item.create_date`]="{ item }">
                                <span class="text-no-wrap">{{ item.create_date | formatDate }}</span>
                            </template>

                            <template v-slot:[`item.status`]="{ item }">
                                <span :class="$statusOTP(item)">{{ $OTPStatusString(item.status) }}</span>
                            </template>

                            <template v-slot:[`item.action`]="{ item }">
                                <div class="contentAddress d-flex justify-start">
                                    <v-tooltip
                                            bottom
                                            max-width="300px"
                                            color="primary"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <span class="otpSuccess" @click="showContent(item)"
                                                  v-show="item.status === 1">Xem SMS</span>
                                            <!--                      <v-btn class="mr-1" dark depressed color="#0C8F4D" @click="showContent(item)" v-show="item.status === 1 && item.type !== 'audio'">Xem SMS</v-btn>-->
                                        </template>
                                        <span>Nội dung SMS</span>
                                    </v-tooltip>

                                    <!--                  <v-tooltip-->
                                    <!--                      bottom-->
                                    <!--                      max-width="300px"-->
                                    <!--                      color="primary"-->
                                    <!--                  >-->
                                    <!--                    <template v-slot:activator="{ on }">-->
                                    <!--                      <v-icon v-on="on" size="12px" color="white" class="mr-1 iconBorderGreen" @click="renewSMS(item)" v-show="item.status === 1">-->
                                    <!--                        autorenew-->
                                    <!--                      </v-icon>-->
                                    <!--                    </template>-->
                                    <!--                    <span>Mua lại</span>-->
                                    <!--                  </v-tooltip>-->

                                    <v-tooltip
                                            bottom
                                            max-width="300px"
                                            color="primary"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" size="12px" color="white" class="mr-1 iconBorderRed"
                                                    @click="cancelSMS(item)" v-show="item.status === 2">
                                                cancel
                                            </v-icon>
                                        </template>
                                        <span>Hủy</span>
                                    </v-tooltip>
                                </div>
                            </template>

                            <template v-slot:no-data>
                                <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
                            </template>
                        </v-data-table>

                        <div class="text-center pt-2">
                            <v-pagination v-model="page" :length="pageCount"></v-pagination>
                        </div>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="isShowContent" max-width="600px" v-if="editedItem">
            <v-card class="elevation-16 mx-auto">
                <v-card-title>
                    <span class="header-title">Nội dung tin nhắn</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-card-text class="px-1">
                        <span> Thời gian: <span class="bold-title"> {{
                            editedItem.create_date | formatDate
                            }} </span> </span> <br/>
                        <span> Số gửi: <span class="bold-title"> {{ editedItem.sender_name }} </span> </span> <br/>
                        <span> Nội dung: <span class="bold-title"> {{ editedItem.content }} </span> </span> <br/>
                    </v-card-text>
                </v-card-text>

                <v-divider></v-divider>
                <v-card-actions class="justify-space-between">
                    <v-spacer></v-spacer>
                    <v-btn dark color="primary" @click="closeContentDialog" width="120px">
                        {{ $t('common.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--        detail item on mobile-->
        <v-dialog v-model="visibleDetailDialog" width="100%" v-if="isMobile && !!detailItem">
            <v-card class="elevation-16 pa-2">
                <div class="d-flex align-center" style="column-gap: 12px">
                    <v-avatar size="64">
                        <img v-if="detailItem.service.image" :src="detailItem.service.image"/>
                        <img v-else src='@/assets/no-photo.png'/>
                    </v-avatar>
                    <div class="detail-content" style="width: calc(100% - 76px)">
                        <div class="d-flex justify-space-between" style="width: 100%">
                            <div class="font-weight-bold" style="font-size:17px">{{ detailItem.service.name }}</div>
                            <v-avatar size="16">
                                <img src='@/assets/close_icon.svg' @click="closeDetailDialog"/>
                            </v-avatar>
                        </div>
                        <div class="wrap-text">
                            {{ $t('buy_code_management.create_date') }}: {{ detailItem.create_date | formatDate }}
                        </div>
                        <div class="wrap-text">
                            Sender name: {{ detailItem.sender_name }}
                        </div>
                        <div class="wrap-text">
                            {{ $t('buy_code_management.phone') }}: {{ detailItem.sim.phone }}
                            <v-icon color="primary" small class="mr-2"
                                    @click="copyClipboardInDialog(detailItem.sim.phone)"
                                    v-if="detailItem.sim.phone">
                                file_copy
                            </v-icon>
                        </div>
                        <div>
                            Nội dung: <span v-if="detailItem.type !== 'audio'">{{ detailItem.content }}</span>
                            <vuetify-audio v-else :file="detailItem.audioMp3" color="primary"></vuetify-audio>
                        </div>
                        <div class="wrap-text">
                            {{ $t('buy_code_management.code') }}: {{ detailItem.otp }}
                            <v-icon color="primary" small class="mr-2" @click="copyClipboardInDialog(detailItem.otp)"
                                    v-if="detailItem.otp">
                                file_copy
                            </v-icon>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>

        <v-snackbar
                v-model="snackbar"
                :color="color"
                :timeout="2000"
                top
                right
                elevation="24"
                class="mt-5"
        >
            {{ message }}
        </v-snackbar>

        <v-overlay :value="overlay" :z-index="0">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
import i18n from '@/plugins/i18n';
import socketClient from "@/plugins/socketClient";
import {isMobileBrowser} from "@/utils/Device";
import InfoItem from "@/components/InfoItem.vue";

export default {
    components: {InfoItem},
    data: () => ({
        page: 1,
        overlay: false,
        pageCount: 0,
        totalItem: 0,
        itemsPerPage: 50,
        loading: false,
        headerNumber: '',
        numberPage: [
            50, 100, 200
        ],
        serviceName: '',
        selectNetwork: {name: "Ngẫu nhiên", id: -1},
        networks: [],

        selectService: {},
        services: [],
        allServices: [],
        search: '',
        dialog: false,
        userStatus: 2,

        snackbar: false,
        message: '',
        color: '#0C8F4D',

        isShowContent: false,
        editedItem: null,

        headers: [
            {text: '#', align: 'left', sortable: false, value: 'index',},
            {text: i18n.t('buy_code_management.create_date'), value: 'create_date'},
            {text: i18n.t('buy_code_management.service'), value: 'service'},
            {text: i18n.t('buy_code_management.price'), value: 'payment'},
            {text: i18n.t('buy_code_management.phone'), value: 'sim'},
            {text: i18n.t('buy_code_management.code'), value: 'type'},

            {text: i18n.t('buy_code_management.status'), value: 'status'},
            // { text: i18n.t('credit_management.user'), value: 'user_id' },
            {text: '', value: 'action', sortable: false}
        ],

        desserts: [],
        stringRules: [
            v => !!v || i18n.t('validate.not_empty'),
            // v => (v && v.length > 5) || i18n.t('validate.string_invalid')
        ],
        selectedService: null,
        visibleDetailDialog: false,
        detailItem: null
    }),

    computed: {
        isMobile() {
            return isMobileBrowser()
        }
    },

    watch: {
        itemsPerPage() {
            this.page = 1;
            console.log("itemsPerPage")
            this.getAllOTP();
        },

        page() {
            // trên mobile thì nối tiếp danh sách list, k reset về [] trước khi lấy
            this.getAllOTP(!this.isMobile);
        },

        serviceName() {
            this.filterServiceByName(this.serviceName);
        }
    },

    async created() {
        await Promise.all([
            this.getAllNetwork(),
            this.getAllService()
        ])
        await this.getAllOTP()

        const response = await this.$getInformationUser();

        if (response.status === 200) {
            this.userStatus = response.data.status;
        }

        socketClient.getIO().on('notifications-otp', (msg) => {
            console.log(msg);
            this.desserts.forEach((element, index) => {
                if (element.sim.phone === msg.phone && element.status == 2) {
                    this.desserts[index].create_date = msg.time
                    this.desserts[index].status = msg.status;

                    if (msg.status === 1) {
                        // this.desserts[index].create_date = msg.time
                        // this.desserts[index].otp = msg.code;
                        // this.desserts[index].status = msg.status;
                        // this.desserts[index].content = msg.content;
                        Object.assign(this.desserts[index], msg);
                    }

                    return;
                }
            });
        });

        // socketClient.getIO().on('notifications-otp-init', (msg) => {
        //   this.desserts.unshift(msg);
        //   this.desserts.forEach(
        //       (item, index) => {
        //         item.index = (this.page - 1) * this.itemsPerPage + index + 1;
        //       }
        //   );
        // });
    },

    methods: {
        async getAllOTP(resetData = true) {
            try {
                this.loading = true;
                const response = await this.$getAllOTPToday(this.page - 1, this.itemsPerPage);
                this.loading = false;

                if (response.status === 200) {
                    if (resetData || this.page === 1) {
                        this.desserts = [].concat(response.data.content);
                    } else {
                        this.desserts = [...this.desserts, ...response.data.content];
                    }
                    this.pageCount = response.data.totalPages;
                    this.totalItem = response.data.totalElements;
                    this.desserts.forEach(
                        (item, index) => {
                            item.index = (this.page - 1) * this.itemsPerPage + index + 1;
                        }
                    );
                }
            } catch (e) {
                console.log(e.stack);
            }
        },

        async hireSIM(service) {
            if (this.userStatus == 2) {
                await this.$fire({
                    title: "Thông báo",
                    text: "Vui lòng nạp tối thiểu 50k để sử dụng (Tài khoản có luôn 50k sau khi nạp)",
                    type: "warning"
                });
                return;
            }

            if (service == null) {
              this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
              this.message = "Vui lòng chọn dịch vụ mua số!";
              this.snackbar = true;

              return;
            }

            try {
                this.overlay = true;
                const response = await this.$getSIMCustomer(service.id, this.selectNetwork.id, this.headerNumber)
                this.overlay = false;

                if (response.status === 200) {
                    this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
                    this.message = 'Lấy số thành công!'
                    this.snackbar = true;
                    this.desserts.unshift(response.data);
                    this.desserts.forEach(
                        (item, index) => {
                            item.index = (this.page - 1) * this.itemsPerPage + index + 1;
                        }
                    );
                    // await this.getAllOTP();
                } else if (response.status === 312) {
                    this.color = process.env.VUE_APP_WARNING_COLOR_TOAST;
                    this.message = response.data
                    this.snackbar = true;

                    return;
                } else if (response.data) {
                    this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
                    this.message = response.data;
                    this.snackbar = true;

                    return;
                } else {
                    alert(i18n.t('validate.request_error'));
                }
            } catch (e) {
                console.log(e.stack);
                alert(i18n.t('validate.request_error'));
            }

        },

        copyClipboard(value) {
            const self = this;
            this.$copyText(value).then(function () {
                self.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
                self.message = 'Copy thành công ' + value;
                self.snackbar = true;
            }, function () {
                self.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
                self.message = 'Copy không thành công ' + value;
                self.snackbar = true;
            })
        },
        // vue-clipboard2 not support text in dialog
        copyClipboardInDialog(value) {
            const el = document.createElement('textarea');
            el.addEventListener('focusin', e => e.stopPropagation());
            el.value = value;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
            this.message = 'Copy thành công ' + value;
            this.snackbar = true;
        },

        async renewSMS(item) {
            try {
                this.overlay = true;
                const response = await this.$renewSim(item.sim.id, item.service.id)
                this.overlay = false;

                if (response.status === 200) {
                    this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
                    this.message = 'Lấy lại số thành công!'
                    this.snackbar = true;

                    this.desserts.unshift(response.data);
                    this.desserts.forEach(
                        (item, index) => {
                            item.index = (this.page - 1) * this.itemsPerPage + index + 1;
                        }
                    );
                } else if (response.status === 312) {
                    this.color = process.env.VUE_APP_WARNING_COLOR_TOAST;
                    this.message = response.data
                    this.snackbar = true;

                    return;
                } else if (response.data) {
                    this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
                    this.message = response.data;
                    this.snackbar = true;

                    return;
                } else {
                    this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
                    this.message = i18n.t('validate.request_error')
                    this.snackbar = true;
                }
            } catch (e) {
                console.log(e.stack);
                alert(i18n.t('validate.request_error'));
            }
        },

        async cancelSMS(item) {
            let index = this.desserts.indexOf(item);
            const self = this;
            const response = await this.$cancelSIM(item.sim.id);
            if (response.status === 200) {
                self.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
                self.message = 'Hủy thành công!';
                self.snackbar = true;
                this.desserts[index].status = 3;
                // await this.getAllOTP();
            } else {
                self.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
                self.message = 'Hủy không thành công!';
                self.snackbar = true;
            }
        },

        async getAllNetwork() {
            try {
                const response = await this.$getAllNetworkService(0, 200);

                if (response.status === 200) {
                    this.networks = [{name: "Ngẫu nhiên", id: -1}].concat(response.data.content);
                }
            } catch (e) {
                console.log(e.stack);
            }
        },

        filterServiceByName(name) {
            if (!name || name.length == 0) {
                this.services = [].concat(this.allServices);
            } else {
                this.services = [].concat(this.allServices.filter(service => service.name.toLowerCase().includes(name.toLowerCase())))
            }

        },

        async getAllService() {
            try {
                const response = await this.$getAllServiceForUser(0, 1000);
                if (response.status === 200) {
                  let results = response.data.content;
                  results = results.sort((a, b) => {
                    return a.id - b.id;
                  });

                  this.services = [].concat(results);
                  this.allServices = [].concat(results);
                }
            } catch (e) {
                console.log(e.stack);
            }
        },

        showContent(item) {
            this.editedItem = item;

            this.isShowContent = true;
        },

        closeContentDialog() {
            this.isShowContent = false;
            this.editedItem = null;
        },
        showDetailDialog(item) {
            this.visibleDetailDialog = true
            this.detailItem = item
        },
        closeDetailDialog() {
            this.visibleDetailDialog = false
            this.detailItem = null
        },
        onScrollList() {
            const container = document.querySelector('.container-class');
            const scrollPosition = container.scrollTop + container.offsetHeight;
            const totalHeight = container.scrollHeight;
            if (scrollPosition >= totalHeight && this.page < this.pageCount && !this.loading) {
                this.page += 1
            }
        },
    }
}
</script>

<style scoped>
.bank-title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
}

.bold-title {
    font-style: normal;
    font-weight: bold;
}

.notice-title {
    color: #FF0000;
    font-style: normal;
    font-weight: bold;
}

.v-btn:hover {
    background-color: #228EF0 !important;
}


.wrap-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.detail-content > div {
    margin-bottom: 8px
}
</style>
